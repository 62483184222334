import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Progress from "../components/Progress";
import FormMandate from "../components/FormMandate";
import Signature from "../components/Signature";
import InsuranceSelection from "../components/InsuranceSelection";
import Forest from "../assets/img/lake.jpg";
import { Modal, ModalProvider } from "../components/ModalComponents";
import familypic from "../assets/img/family-forest.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  const { t } = useTranslation(); // Use translation hook
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    personalData: {},
    signature: "",
    selectedInsurances: [],
  });

  const steps = [
    {
      id: "01",
      name: t("steps.personalData"),
      href: "#",
      status: step === 0 ? "current" : "upcoming",
    },
    {
      id: "02",
      name: t("steps.sign"),
      href: "#",
      status: step === 1 ? "current" : "upcoming",
    },
    {
      id: "03",
      name: t("steps.insurance"),
      href: "#",
      status: step === 2 ? "current" : "upcoming",
    },
  ];

  const handleNextStep = async (data) => {
    const updatedFormData = {
      ...formData,
      ...data,
    };

    setFormData(updatedFormData);
    await submitData(updatedFormData);

    setStep((prevStep) => prevStep + 1);
  };

  const submitData = async (data) => {
    const response = fetch(
      "https://ezc3ugozhc.execute-api.eu-central-1.amazonaws.com/prd/mandate",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      console.error(t("form.submitError"));
    } else {
      console.log(t("form.submitSuccess"));
    }
  };

  const updateStepStatuses = (currentStep) => {
    return steps.map((step, index) => {
      if (index < currentStep) {
        return { ...step, status: "complete" };
      } else if (index === currentStep) {
        return { ...step, status: "current" };
      } else {
        return { ...step, status: "upcoming" };
      }
    });
  };

  // Update steps based on the current step index
  const updatedSteps = updateStepStatuses(step);

  return (
    <ModalProvider>
      <HelmetProvider>
        <Helmet>
          <title>Mandat - Lang + Partner</title>
          <meta
            name="description"
            content="Füllen Sie das Mandat aus und unterzeichnen Sie es, um von den umfassenden Versicherungsdiensten von Lang + Partner zu profitieren."
          />
          <meta property="og:title" content="Mandat - Lang + Partner" />
          <meta
            property="og:description"
            content="Füllen Sie das Mandat aus und unterzeichnen Sie es, um von den umfassenden Versicherungsdiensten von Lang + Partner zu profitieren."
          />
          <meta property="og:image" content={familypic} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:title" content="Mandat - Lang + Partner" />
          <meta
            property="twitter:description"
            content="Füllen Sie das Mandat aus und unterzeichnen Sie es, um von den umfassenden Versicherungsdiensten von Lang + Partner zu profitieren."
          />
          <meta property="twitter:image" content={familypic} />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      </HelmetProvider>

      <Navbar />
      <Banner image={Forest} />
      <Progress steps={updatedSteps} />
      {step === 0 && (
        <FormMandate
          onNext={(data) => handleNextStep({ personalData: data })}
        />
      )}
      {step === 1 && (
        <Signature onNext={(data) => handleNextStep({ signature: data })} />
      )}
      {step === 2 && (
        <InsuranceSelection
          onNext={(data) => handleNextStep({ selectedInsurances: data })}
        />
      )}
      {step > 2 && (
        <p className="text-center my-4">{t("steps.requestReceived")}</p>
      )}
      <Footer />
    </ModalProvider>
  );
}

export default App;
